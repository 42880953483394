@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

a {
    color: inherit;
    text-decoration: none;
    transition: 0.3s;
}
a:hover {
    color: inherit;
    text-decoration: underline;
    text-decoration-color: inherit;
    transition: 0.3s;
}

h1, h2, h3, h4, h5, h6, p, a, div, body, html, li, ul, ol, span {
    font-family: "Manrope", sans-serif !important;
}

.span-as-pre {
    white-space: pre;
    font-family: monospace !important;
    font-weight: bold;
}

.rbc-event-label {
    display: none !important;
}